import { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './ChosenHabitsList.module.scss';
import ball from '../../assets/img/tree-toy.svg';
import logo from '../../assets/img/logo.svg';
import santaClaus from '../../assets/img/santa-claus.svg';
import { NavLink } from 'react-router-dom';
import { chosenHabitsText } from '../../data/siteText';
import { useAllHabits } from './../../hooks/useAllHabits';
import BootstrapModal from '../../components/_UI/BootstrapModal/BootstrapModal';
import Pdf from '../Pdf/Pdf';
import EmailModal from './../../components/_UI/EmailModal/EmailModal';

const cn = classNames.bind(styles);

const ChosenHabitsList = () => {
	const [modalShow, setModalShow] = useState(false);

	const { allHabits } = useAllHabits();

	return (
		<>
			<BootstrapModal
				show={modalShow}
				onHide={() => setModalShow(false)}
				type="email"
			/>

			<div className="main-wrapper">
				<div className={cn('background-wrapper')}>
					<div className={cn('background')}
					></div>
				</div>

				<div className="container-xl position-fixed end-0 start-0 z-100">
					<NavLink to="/" className={cn('logo')}>
						<img
							src={logo}
							alt=""
						/>
					</NavLink>
					<div className="position-absolute top-0 end-0 mt-2">
						<NavLink to='/rating/' className='btn btn-primary text-nowrap me-2'>Рейтинг</NavLink>
						<NavLink to="/" className='btn btn-primary text-nowrap me-2'>Начать заново</NavLink>
					</div>
				</div>

				<div className="container-xl position-relative">
					<div className={cn('row g-0', 'row-main')}>
						<div className={cn('left-side', 'col-xl-6')}>
							<div className={cn('inner')}>
								<img
										className={cn('left-image')}
										src={santaClaus}
										alt=""
									/>
								<EmailModal setModalShow={setModalShow} />
							</div>
						</div>
						<div className={cn('right-side', 'col-xl-6 flex-grow-1')}>
							<div className={cn('content')}>
								<div className={cn('text-content')}>
									<h2 className={cn('content-title')}>{chosenHabitsText.title}</h2>
									<p className={cn('content-text m-0')}>{chosenHabitsText.desc}</p>
								</div>
								<ul className={cn('habits-list')}>
									{
										allHabits.map((habit) =>
											<li className={cn('habit')} key={habit.id}>
												<img src={ball} alt="" />
												<p className={cn('habit-text')}>
													{habit.changedText ? habit.changedText : habit.text}
												</p>
											</li>
										)
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{display: 'none'}}>
				<Pdf />
			</div>
		</>
	)
}

export default ChosenHabitsList;
